import React from "react"
import Section from "../global/Section"
import Tutorial_1 from"../../images/home/tutorial1.png"
import Tutorial_2 from"../../images/home/tutorial2.png"
import Tutorial_3 from"../../images/home/tutorial3.png"
import Tutorial_4 from"../../images/home/tutorial4.png"

export default function Onboard() {
 

  return (
    <Section secStyle={"sec-gridBg"} extraMargin={"44px 0"}>
      
      <div className="row align-items-center justify-content-center">
        <div className="col-sm-12 py-4">
          <h3 className="font-w-extrabolditalic text-center font-w-bold font-italic my-3">
            How it works
          </h3>
        </div>
        </div>
        <div className="row align-items-center">
              <div className="col-lg-3 col-md-3 col-sm-12 my-3"> 
                <img src={Tutorial_1} width="100%" className="d-block m-auto"/>
                <h4 className="font-w-extrabolditalic text-center font-w-extraBold font-italic my-3">
                  1. Download
                </h4>
                <p className="d-grey text-center">Download the game from Google Play, and start the game.</p>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-12 my-3"> 
                <img src={Tutorial_2} width="100%" className="d-block m-auto"/>
                <h4 className="font-w-extrabolditalic text-center font-w-extraBold font-italic my-3">
                  2. Choose a game
                </h4>
                <p className="d-grey text-center">Find the games you love and try them out.</p>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-12 my-3"> 
                <img src={Tutorial_3} width="100%" className="d-block m-auto"/>
                <h4 className="font-w-extrabolditalic text-center font-w-extraBold font-italic my-3">
                  3. Play for Free
                 </h4>
                 <p className="d-grey text-center">Compete with players around the globe. No payment needed!</p>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-12 my-3"> 
                <img src={Tutorial_4} width="100%" className="d-block m-auto"/>
                <h4 className="font-w-extrabolditalic text-center font-w-extraBold font-italic my-3">
                  4. Make new friends
                </h4>
                <p className="d-grey text-center">Have fun and meet players around the world!</p>
              </div>
        </div>
       
    </Section>
  )
}
